import { Controller } from "@hotwired/stimulus"
import SlimSelect from 'slim-select'
import 'slim-select/dist/slimselect.css'


// Connects to data-controller="slim-select"
export default class extends Controller {
  static targets = ['field']
  connect() {
    this.select = new SlimSelect({
      select: this.element
    })
  }
  disconnect() {
    this.select.destroy()
  }
}